import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import '../styles/globals.css';

function MyApp({ Component, pageProps }) {
  const [appContext, setAppContext] = useState();
  return (
    <Component
      {...pageProps}
      appContext={appContext}
      setAppContext={setAppContext}
    />
  );
}

export default MyApp;
